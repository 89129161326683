<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { mdAndUp, smAndUp } = useDisplay()

defineComponent({
  name: 'FooterComponent'
})
</script>

<template>
  <div>
    <div class="px-4 py-0 mx-0 my-0 bg-primary">
      <v-container fluid class="pa-0 ma-0">
        <div class="maxWidth">
          <v-row class="py-16">
            <v-col v-if="mdAndUp" cols="12" lg="6">
              <Logo color="#ffffff" />
            </v-col>
            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12">
                  <v-row no-gutters class="px-4">
                    <v-col cols="12" md="6">
                      <p class="text-h3 font-fira-code pb-3">dreißigelf</p>
                      <p class="text-body-1 font-weight-bold">Tiefenbruchstraße 88</p>
                      <p class="text-body-1 font-weight-bold pb-3">40764 Langenfeld (Rhld.)</p>
                      <p class="text-body-1 font-weight-bold">
                        <NuxtLink class="footer-link" href="tel:+4921225087380"
                          >+49 212 25087380</NuxtLink
                        >
                      </p>
                      <p class="text-body-1 font-weight-bold">
                        <NuxtLink class="footer-link" href="mailto:info@dreissigelf.de"
                          >info@dreissigelf.de</NuxtLink
                        >
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-10 pt-md-0">
                      <p class="text-h3 pb-3">Sitemap</p>
                      <p class="text-body-1 font-weight-bold pb-1">
                        <NuxtLink class="footer-link" href="/leistungen">Leistungen</NuxtLink>
                      </p>
                      <p class="text-body-1 font-weight-bold pb-1">
                        <NuxtLink class="footer-link" href="/leistungen">Lösungen</NuxtLink>
                      </p>
                      <p class="text-body-1 font-weight-bold pb-1">
                        <NuxtLink class="footer-link" href="/leistungen">Referenzen</NuxtLink>
                      </p>
                      <p class="text-body-1 font-weight-bold pb-1">
                        <NuxtLink class="footer-link" href="/leistungen">Über uns</NuxtLink>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-divider />
      <v-container fluid class="pa-0 ma-0">
        <div class="maxWidth">
          <v-row class="py-4" no-gutters>
            <v-col cols="12" sm="6" :align="smAndUp ? 'start' : 'center'">
              <span class="text-h5">&copy; 2024 </span>
              <span class="text-h5 font-fira-code">dreißigelf</span>
            </v-col>
            <v-col cols="12" sm="6" :align="smAndUp ? 'end' : 'center'" class="pt-2 pt-sm-0">
              <NuxtLink class="footer-link" href="/agb">AGB</NuxtLink>
              <span class="text-h5 mx-2">|</span>
              <NuxtLink class="footer-link" href="/datenschutz">Datenschutz</NuxtLink>
              <span class="text-h5 mx-2">|</span>
              <NuxtLink class="footer-link" href="/impressum">Impressum</NuxtLink>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-link {
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: rgb(var(--v-theme-accent));
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
}

.v-divider {
  border-color: rgb(var(--v-theme-accent)) !important;
  opacity: 1 !important;
}

.font-fira-code {
  font-family: 'Fira Code', monospace;
}
</style>
